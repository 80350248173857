import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import ProcessAccordion
  from '@shipae/components-sandbox/component/process-accordion';
import {
  InfographicsWithPhases,
} from 'src/infrastructure/gatsby/types/infographics-with-phases';
import styled from 'styled-components';
import { MobileSection } from '../../shared/mobile-section';
import { Heading as SharedHeading } from '../../shared/heading';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

const Heading = styled(SharedHeading).attrs({ as: 'div' })`
  margin-bottom: 13.8rem;
`;

type Props = Omit<
  InfographicsWithPhases,
  'id' | 'infographic' | 'tabsStyle'
>

const MobileProcessAccordion: React.FC<Props> = ({
  sectionId,
  title,
  infographicsPhase,
  topIconOnMobile,
  bottomIconOnMobile,
}) => (
  <MobileSection id={sectionId}>
    <Heading>{title}</Heading>
    <ProcessAccordion
      startIconUrl={topIconOnMobile?.localFile?.publicURL}
      startIconAlt={topIconOnMobile?.alternativeText || ''}
      endIconUrl={bottomIconOnMobile?.localFile?.publicURL}
      endIconAlt={bottomIconOnMobile?.alternativeText || ''}
      entries={infographicsPhase?.map((phase) => (
        {
          iconUrl: phase.iconOnMobile?.localFile?.publicURL,
          iconAlt: phase.iconOnMobile?.alternativeText,
          accentColor: phase.colorHex,
          title: phase.title,
          content: (
            <ReactMarkdown options={gloabalReactMdOptions}>
              {phase.description || ''}
            </ReactMarkdown>
          ),
        })) || []}
    />
  </MobileSection>
);

export default MobileProcessAccordion;

/**
 * Converts HEX color to rgba.
 */
export const rgba = (hex: string, alpha: number): string => {
  const newHex = hex.replace('#', '');
  const r = parseInt(newHex.length === 3
    ? newHex.slice(0, 1).repeat(2) : newHex.slice(0, 2), 16);
  const g = parseInt(newHex.length === 3
    ? newHex.slice(1, 2).repeat(2) : newHex.slice(2, 4), 16);
  const b = parseInt(newHex.length === 3
    ? newHex.slice(2, 3).repeat(2) : newHex.slice(4, 6), 16);
  if (alpha) {
    return `rgba(${ r }, ${ g }, ${ b }, ${ alpha })`;
  }

  return `rgb(${ r }, ${ g }, ${ b })`;
};

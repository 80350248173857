import { AppState } from '../../state';
import { initialState, InfographicsState } from './reducer';

export const getInfographics = (
  state: AppState,
): InfographicsState => (
  state.infographics
    ? state.infographics
    : initialState
);

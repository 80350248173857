import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { GatsbyImageProps } from 'gatsby-image';
import Tab from '@shipae/components-sandbox/component/tabs/tab';
import TabThemeNeutral, { TabThemeType }
  from '@shipae/components-sandbox/component/tabs/tab/theme';
import TabThemeFreight
  from '@shipae/components-sandbox/component/tabs/tab/theme/shipa-freight';
import TabThemeDelivery
  from '@shipae/components-sandbox/component/tabs/tab/theme/shipa-delivery';
import TabThemeEcommerce
  from '@shipae/components-sandbox/component/tabs/tab/theme/shipa-ecommerce';
import Tabs from '@shipae/components-sandbox/component/tabs';
import TabsThemeNeutral, { TabsThemeType }
  from '@shipae/components-sandbox/component/tabs/theme';
import TabsThemeFreight
  from '@shipae/components-sandbox/component/tabs/theme/shipa-freight';
import TabsThemeDelivery
  from '@shipae/components-sandbox/component/tabs/theme/shipa-delivery';
import TabsThemeEcommerce
  from '@shipae/components-sandbox/component/tabs/theme/shipa-ecommerce';
import Tooltip, {
  TooltipTheme,
} from '@shipae/components-sandbox/component/tooltip';

import {
  InfographicsWithPhases,
} from 'src/infrastructure/gatsby/types/infographics-with-phases';
import styled, { css } from 'styled-components';
import { fonts, media } from 'src/views/theme';
import {
  cleanWhite, jetBlack,
} from '@shipae/components-sandbox/component/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  setInfographicsActiveId,
} from 'src/application/lazy/infographics/actions';
import {
  getInfographics,
} from 'src/application/lazy/infographics/selectors';
import { loadComponent } from 'src/application/core/control/actions';
import { rgba } from './utils';
import { DesktopSection as Section } from '../../shared/desktop-section';
import { Heading } from '../../shared/heading';
import gloabalReactMdOptions from '../../shared/react-makdown-options';

const DesktopSection = styled(Section)`
  padding: 12rem calc((100% - var(--container-width)) / 2) 6rem;
  margin-bottom: 35rem;

  ${ media.max(1024) } {
    margin-bottom: 0;
  }
`;

const Center = styled.div`
  text-align: center;
`;

const HorizontalScrollContainer = styled.div`
  width: 100%;
  ${ media.max(1024) } {
    padding-bottom: 40rem;
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

const ReferenceContainer = styled.div`
  position: relative;
  width: 100%;

  ${ media.max(1024) } {
    width: 1024px;
  }
`;

const InfographicBaseCss = css`
  display: block;
  width: 100%;
  max-width: 100%;
  margin-top: 5.6rem;
`;

const InfographicImage = styled(GatsbyImage)<GatsbyImageProps>`
  ${ InfographicBaseCss }
`;

const InfographicSvg = styled.img`
  ${ InfographicBaseCss }
`;

interface DotProps {
  color: string;
  horizontalPosition: number;
}

const InteractiveCircle = styled.span<DotProps>`
  position: absolute;
  left: ${ (pr) => pr.horizontalPosition }%;
  transform: translate(-${
  (pr) => (pr.horizontalPosition < 20 ? 0 : 50)
}%, 120%);
  bottom: 0;

  display: inline-block;
  width: 4rem;
  height: 4rem;
  background-color: ${ cleanWhite() };
  border-radius: 50%;
  box-shadow: 0 0 0 0.42rem ${ (pr) => rgba(pr.color, 0.5) };
  cursor: pointer;
`;

interface InnerCircleProps {
  diameter: number;
  color: string;
}

const InnerCircle = styled.span<InnerCircleProps>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${ (pr) => pr.diameter }rem;
  height: ${ (pr) => pr.diameter }rem;
  background-color: ${ (pr) => pr.color };
  border-radius: 50%;
`;

interface CustomTooltipProps {
  color: string;
  leftSided: boolean;
}

const TooltipWrapper = styled.div<CustomTooltipProps>`
  & [data-css=tooltip-offset] {
    left: calc(50% + ${ (pr) => (pr.leftSided ? 11.2 : -11.2) }rem);

  }
  & [data-css=tooltip-main] {
    background-color: ${ (pr) => pr.color };
    /* Arrow */
  &:before {
    border-bottom-color: ${ (pr) => pr.color };
    transform: translate(calc(-50% - ${
  (pr) => (pr.leftSided ? 11.2 : -11.2)
}rem), -100%);
    [dir=rtl] & {
      transform: translate(calc(-50% - ${
  (pr) => (pr.leftSided ? -13.2 : 9.2)
}rem), -100%);
    }
  }
  }
`;

const CustomTooltip = {
  TransparentOffset: styled(TooltipTheme.TransparentOffset).attrs({
    'data-css': 'tooltip-offset',
  })`
  bottom: ${ (pr) => -1 * ((pr.tooltipHeight ? pr.tooltipHeight : 0) + 48) }px;
  `,
  Main: styled(TooltipTheme.Main).attrs({
    'data-css': 'tooltip-main',
  })`
  padding: 2.4rem 1.6rem;
  width: 44.8rem;
  border-radius: 4px;
  cursor: auto;

  ${ fonts.bodyl100 }
`,
};

interface PhaseLogoProps {
  visible: boolean;
}

const PhaseLogo = styled.img<PhaseLogoProps>`
  visibility: ${ (pr) => (pr.visible ? 'visible' : 'hidden') };
  opacity: ${ (pr) => (pr.visible ? 1 : 0) };
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 120%);
`;

type Props = Omit<
  InfographicsWithPhases,
  'id' | 'topIconOnMobile' | 'bottomIconOnMobile'
>;

type TabThemeListType = {
  tabs: TabsThemeType,
  tab: TabThemeType,
};
type TabsThemesListType = {
  [index: string]: TabThemeListType,
  neutral: TabThemeListType,
  shipaFreight: TabThemeListType,
  shipaDelivery: TabThemeListType,
  shipaEcommerce: TabThemeListType,
};

const sharedTabsCss = css`
  font-size: 2rem;
  font-weight: 300;
`;
const TabsThemes: TabsThemesListType = {
  neutral: {
    tabs: TabsThemeNeutral,
    tab: {
      ...TabThemeNeutral,
      Main: styled(TabThemeNeutral.Main).attrs({ as: 'h3' })`
        ${ sharedTabsCss }
      `,
    },
  },
  shipaFreight: {
    tabs: TabsThemeFreight,
    tab: {
      ...TabThemeFreight,
      Main: styled(TabThemeFreight.Main).attrs({ as: 'h3' })`
        ${ sharedTabsCss }
      `,
    },
  },
  shipaDelivery: {
    tabs: TabsThemeDelivery,
    tab: {
      ...TabThemeDelivery,
      Main: styled(TabThemeDelivery.Main).attrs({ as: 'h3' })`
        ${ sharedTabsCss }
      `,
    },
  },
  shipaEcommerce: {
    tabs: TabsThemeEcommerce,
    tab: {
      ...TabThemeEcommerce,
      Main: styled(TabThemeEcommerce.Main).attrs({ as: 'h3' })`
        ${ sharedTabsCss }
      `,
    },
  },
};

const DesktopInfographics: React.FC<Props> = ({
  sectionId,
  title,
  infographic,
  infographicsPhase,
  tabsStyle,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadComponent('infographics'));
  }, []);
  const [tabTheme, setTabTheme] = useState(TabsThemes[tabsStyle]?.tab);
  const [tabsTheme, setTabsTheme] = useState(TabsThemes[tabsStyle]?.tabs);
  const {
    infographicsActivePhaseId: activeTooltipId,
  } = useSelector(getInfographics);

  useEffect(() => {
    setTabTheme(TabsThemes[tabsStyle]?.tab);
    setTabsTheme(TabsThemes[tabsStyle]?.tabs);
  }, [tabsStyle]);

  return (
    <DesktopSection id={sectionId}>
      <Heading>{title}</Heading>
      <Center>
        <Tabs
          value={activeTooltipId || (
            infographicsPhase?.length ? infographicsPhase[0].id : ''
          )}
          onChange={(id: string) => {
            dispatch(setInfographicsActiveId(id));
          }}
          theme={tabsTheme}
        >
          {infographicsPhase?.map((phase, index) => (
            <Tab
              id={phase.id}
              key={phase.id}
              active={
                activeTooltipId
                  ? activeTooltipId === phase.id
                  : index === 0 /* show 1st by default */
              }
              theme={tabTheme}
            >
              {phase.title}
            </Tab>
          ))}
        </Tabs>
      </Center>
      <HorizontalScrollContainer>
        <ReferenceContainer>
          {infographic.localFile?.childImageSharp?.fluid ? (
            <InfographicImage
              fluid={infographic.localFile?.childImageSharp?.fluid}
              alt={infographic.alternativeText || ''}
              objectFit="contain"
              objectPosition="center center"
            />
          ) : (
            <InfographicSvg
              src={infographic.localFile?.publicURL}
              alt={infographic.alternativeText || ''}
              loading="lazy"
            />
          )}
          {infographicsPhase?.map((phase, index) => (
            <InteractiveCircle
              key={phase.id}
              tabIndex={0}
              onClick={() => dispatch(setInfographicsActiveId(phase.id))}
              color={phase.colorHex || jetBlack()}
              horizontalPosition={phase.horizontalPositionPercents || 50}
            >
              <InnerCircle
                diameter={3.1}
                color={rgba(phase.colorHex || jetBlack(), 0.65)}
              />
              <InnerCircle
                diameter={2.4}
                color={cleanWhite()}
              />
              <InnerCircle
                diameter={1.5}
                color={phase.colorHex || jetBlack()}
              />
              {phase.logoAboveTooltip ? (
                <PhaseLogo
                  visible={
                  activeTooltipId
                    ? activeTooltipId === phase.id
                    : index === 0 /* show 1st by default */
                }
                  src={phase.logoAboveTooltip.localFile?.publicURL}
                  loading="lazy"
                  alt={phase.logoAboveTooltip?.alternativeText || ''}
                />
              ) : null}
              <TooltipWrapper
                color={phase.colorHex || jetBlack()}
                leftSided={phase.horizontalPositionPercents
                  ? phase.horizontalPositionPercents < 55
                  : false}
              >
                <Tooltip
                  visible={
                  activeTooltipId
                    ? activeTooltipId === phase.id
                    : index === 0 /* show 1st by default */
                }
                  tooltipPosition="bottom"
                  theme={{
                    ...TooltipTheme,
                    ...CustomTooltip,
                  }}
                >
                  <ReactMarkdown options={gloabalReactMdOptions}>
                    {phase.description || ''}
                  </ReactMarkdown>
                </Tooltip>
              </TooltipWrapper>
            </InteractiveCircle>
          ))}
        </ReferenceContainer>
      </HorizontalScrollContainer>
    </DesktopSection>
  );
};

export default DesktopInfographics;

import React from 'react';
import {
  InfographicsWithPhases as IProps,
} from 'src/infrastructure/gatsby/types/infographics-with-phases';
import DesktopInfographics from './desktop-infographics';
import MobileProcessAccordion from './mobile-process-accordion';

const InfographicsWithPhases: React.FC<IProps> = ({
  title,
  tabsStyle,
  infographic,
  infographicsPhase,
  topIconOnMobile,
  bottomIconOnMobile,
}) => (
  <>
    <MobileProcessAccordion
      title={title}
      infographicsPhase={infographicsPhase}
      topIconOnMobile={topIconOnMobile}
      bottomIconOnMobile={bottomIconOnMobile}
    />
    <DesktopInfographics
      title={title}
      tabsStyle={tabsStyle}
      infographic={infographic}
      infographicsPhase={infographicsPhase}
    />
  </>
);

export default InfographicsWithPhases;
